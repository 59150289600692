import React, { useMemo } from 'react'
import RivataGrid, { IColumn, IRow } from '../../components/RivataGrid'
import { composeDynamicActionsFormatter } from '../../components/RivataGrid/cellFormatter'
import { NoDataLabels } from '../../enums'
import { UnitsOfMeasurementConfig } from '../../constants/constants'
import { celsiusToFahr, psiToBar } from '../../utils'
import { IAssetSensors, IEventDataWarnings } from './types'

type Props = {
  warnings: IEventDataWarnings
  setWarnings: React.Dispatch<React.SetStateAction<IEventDataWarnings>>
  assetSensors: IAssetSensors
  unitsOfMeasurementConfig: UnitsOfMeasurement
}

const SensorDataTable: React.FC<Props> = ({
  warnings,
  setWarnings,
  assetSensors,
  unitsOfMeasurementConfig,
}) => {
  const warninngsColumns: IColumn[] = useMemo(() => {
    const onDelete = (type: string, mac: string) => {
      setWarnings((warnings) => {
        const newWarnings = { ...warnings }

        if (type === 'linePressure' || type === 'axleLoad') {
          newWarnings[type] = undefined
        }

        if (
          type === 'smarthubTemp' ||
          type === 'smarthubVib' ||
          type === 'tpms'
        ) {
          //@ts-expect-error
          const { [mac]: _, ...newTypeWarnings } = newWarnings[type]
          //@ts-expect-error
          newWarnings[type] = newTypeWarnings
        }
        return newWarnings
      })
    }

    return [
      {
        key: 'actions',
        name: 'Actions',
        minWidth: 75,
        width: 75,
        renderCell: ({ row }: any) =>
          composeDynamicActionsFormatter({
            onDelete: () => {
              onDelete(row.type, row.mac)
            },
            cellData: row,
          }),
      },
      { key: 'mac', name: 'Mac' },
      { key: 'position', name: 'Position' },
      { key: 'type', name: 'Warning Type' },
      { key: 'value', name: 'Value' },
    ]
  }, [setWarnings])

  const warningsRows = useMemo(() => {
    const res: Array<IRow> = []

    if (warnings.smarthubTemp)
      Object.entries(warnings.smarthubTemp).forEach(([mac, value]) => {
        res.push({
          mac,
          position:
            assetSensors.smarthub_sensors.find((item) => item.mac === mac)
              ?.position ?? NoDataLabels.DASH,
          type: 'smarthubTemp',
          value:
            unitsOfMeasurementConfig.temperature ===
            UnitsOfMeasurementConfig.temperature.fahrenheit
              ? celsiusToFahr(value).toFixed(1) + ' \u00B0F'
              : value + ' \u00B0C',
        })
      })

    if (warnings.smarthubVib)
      Object.entries(warnings.smarthubVib).forEach(([mac, value]) => {
        const level =
          value.value < 1.5
            ? 'Normal'
            : value.value < 2
            ? 'Abnormal'
            : 'Abnormal High'
        const types = `${value.bearing ? 'Bearing' : ''}${
          value.bearing && value.tire ? ' and ' : ''
        }${value.tire ? 'Tire' : ''}`

        const valueText = value.value < 1.5 ? level : `${level}: ${types}`

        res.push({
          mac,
          position:
            assetSensors.smarthub_sensors.find((item) => item.mac === mac)
              ?.position ?? NoDataLabels.DASH,
          type: 'smarthubVib',
          value: valueText,
        })
      })

    if (warnings.tpms)
      Object.entries(warnings.tpms).forEach(([mac, value]) => {
        res.push({
          mac,
          position:
            assetSensors.tpms.find((item) => item.mac === mac)?.position ??
            NoDataLabels.DASH,
          type: 'tpms',
          value:
            unitsOfMeasurementConfig.pressure ===
            UnitsOfMeasurementConfig.pressure.psi
              ? value + ' psi'
              : psiToBar(value).toFixed(1) + ' bar',
        })
      })

    if (warnings.linePressure !== undefined)
      res.push({
        mac: assetSensors.line_pressure.mac,
        position: assetSensors.line_pressure.position,
        type: 'linePressure',
        value:
          unitsOfMeasurementConfig.pressure ===
          UnitsOfMeasurementConfig.pressure.psi
            ? warnings.linePressure + ' psi'
            : psiToBar(warnings.linePressure).toFixed(1) + ' bar',
      })

    if (warnings.axleLoad !== undefined)
      res.push({
        mac: assetSensors.axle_load.mac,
        position: assetSensors.axle_load.position,
        type: 'axleLoad',
        value:
          unitsOfMeasurementConfig.pressure ===
          UnitsOfMeasurementConfig.pressure.psi
            ? warnings.axleLoad + ' psi'
            : psiToBar(warnings.axleLoad).toFixed(1) + ' bar',
      })

    return res
  }, [warnings, assetSensors, unitsOfMeasurementConfig])

  if (!warningsRows.length) return <></>

  return (
    <div className='pt-2'>
      <RivataGrid columns={warninngsColumns} rows={warningsRows} />
    </div>
  )
}

export default SensorDataTable
