import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
} from 'reactstrap'
import moment from 'moment'
import { Content, OnChangeStatus, toJSONContent } from 'vanilla-jsoneditor'
import ReactJSONEditor from '../../components/ReactJSONEditor'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import api from '../../services/api'
import { convertDataEpochToDate, getCurrentEpoch } from '../../utils'
import { CalendarTypes } from '../../enums'
import RivataModule from '../../components/RivataModule'
import DateTimePicker from '../../componentsV2/DateTimePicker'
import InfoModal from '../../components/InfoModal'
import RivataLoader from '../../components/RivataLoader'
import StatusAlert from '../../components/StatusAlert'
import {
  IAssetSensors,
  IEventDataWarnings,
  IGps,
  IVibrationWarning,
  writeToRivataDbEventData,
} from './types'
import UnitsOfMeasurementSelector from './UnitsOfMeasurementSelector'
import GpsInput from './GpsInput'
import AddSensorDataModal from './AddSensorDataModal'
import SensorDataTable from './SensorDataTable'
import { warningsToSensors } from './utils'

type Props = {
  locale: any
  vin: string
}

const initGps: IGps = {
  latitude: 41.850033,
  longitude: -87.6500523,
  altitude: 0,
  speed: 0,
  direction: 0,
  hdop: 1,
}

const TestHardwareDataGeneration: React.FC<Props> = ({ locale }) => {
  const { assetId, assetSensors, unitsOfMeasurementConfig, timezone } =
    useTypedSelector((state) => ({
      assetId: state.assetAdminInfo.assetInfo.id as number,
      assetSensors: state.assetAdminInfo.sensorInfo.data as IAssetSensors,
      unitsOfMeasurementConfig: state.auth.preferences
        .unitsOfMeasurementConfig as UnitsOfMeasurement,
      timezone: state.auth.preferences.timezone,
    }))

  const [isLoading, setIsLoading] = useState(true)

  const [gatewayMac, setGatewayMac] = useState('')
  const [epoch, setEpoch] = useState(getCurrentEpoch())
  const [isCustomEpoch, setIsCustomEpoch] = useState(false)
  const [ignition, setIgnition] = useState(true)
  const [fetchedGps, setFetchedGps] = useState(initGps)
  const [gps, setGps] = useState(initGps)
  const [warnings, setWarnings] = useState<IEventDataWarnings>({})

  const [json, setJson] = useState<writeToRivataDbEventData | any>([])
  const [jsonEditorContent, setJsonEditorContent] = useState<Content>({
    json: [],
  })
  const [jsonEditorError, setJsonEditorError] = useState(false)
  const [previewJSON, setPreviewJSON] = useState(false)
  const [canEditJSON, setCanEditJSON] = useState(false)

  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false)
  const [info, setInfo] = useState('')

  const [overwrittenUnitsConfig, setOverwrittenUnitsConfig] = useState(
    unitsOfMeasurementConfig,
  )

  useEffect(() => {
    setOverwrittenUnitsConfig(unitsOfMeasurementConfig)
  }, [unitsOfMeasurementConfig])

  useEffect(() => {
    if (canEditJSON || isCustomEpoch) return

    setEpoch(getCurrentEpoch())
    const interval = setInterval(() => {
      setEpoch(getCurrentEpoch())
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [canEditJSON, isCustomEpoch])

  useEffect(() => {
    if (!assetSensors.gateways) return
    setGatewayMac(assetSensors.gateways[0].esn)
  }, [assetSensors])

  const fetchGps = useCallback(() => {
    if (!assetId) return

    setIsLoading(true)

    api
      .getAssetGpsDetails(assetId)
      .then((res) => {
        const newGps = {
          latitude: res.gps_details.latitude ?? 0,
          longitude: res.gps_details.longitude ?? 0,
          altitude: res.gps_details.elevation ?? 0,
          speed: res.gps_details.speed ?? 0,
          direction: res.gps_details.heading ?? 0,
          hdop: res.gps_details.hdop ?? 1,
        }

        setFetchedGps(newGps)
        setGps(newGps)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [assetId])

  useEffect(() => fetchGps(), [fetchGps])

  useEffect(() => {
    setJson([
      {
        gateway_mac: gatewayMac,
        epoch: epoch,
        metadata: {
          inputs: {
            ignition: ignition ? 1 : 0,
          },
        },
        gps: gps,
        sensors: warningsToSensors(warnings, assetSensors, epoch),
      },
    ])
  }, [gatewayMac, epoch, ignition, gps, warnings, assetSensors])

  const onAddWarning = useCallback(
    (warningType: string, mac: string, value: number | IVibrationWarning) => {
      setWarnings((warnings) => {
        switch (warningType) {
          case 'smarthubTemp':
            if (!warnings.smarthubTemp) warnings.smarthubTemp = {}
            warnings.smarthubTemp[mac] = +value
            break

          case 'smarthubVib':
            if (!warnings.smarthubVib) warnings.smarthubVib = {}
            warnings.smarthubVib[mac] = value as IVibrationWarning
            break

          case 'tpms':
            if (!warnings.tpms) warnings.tpms = {}
            warnings.tpms[mac] = +value
            break

          case 'linePressure':
            warnings.linePressure = +value
            break

          case 'axleLoad':
            warnings.axleLoad = +value
            break
        }

        return JSON.parse(JSON.stringify(warnings))
      })
    },
    [],
  )

  const onSubmit = useCallback(() => {
    setIsSubmitModalOpen(false)
    setIsLoading(true)

    api
      .testingWriteToRivataDb(json)
      .then(() => {
        setInfo('Data is submitted. It might take time to process.')
      })
      .catch((error) => setInfo(error.message))
      .finally(() => setIsLoading(false))
  }, [json])

  useEffect(() => {
    if (!canEditJSON) {
      setJsonEditorContent({ json })
    }
  }, [json, canEditJSON])

  const onJsonEditorChange = useCallback(
    (content: Content, pc: Content, status: OnChangeStatus) => {
      if (canEditJSON) {
        setJsonEditorContent(content)
        setJsonEditorError(!!status.contentErrors)
        if (!status.contentErrors) {
          setJson(toJSONContent(content).json)
        }
      }
    },
    [canEditJSON],
  )

  return (
    <>
      <RivataModule
        title='Test Hardware Data Generation'
        locale={locale}
        marginTop={0}
        filters={undefined}
      >
        {isLoading && <RivataLoader />}

        <div>
          <UnitsOfMeasurementSelector
            overwrittenUnitsConfig={overwrittenUnitsConfig}
            setOverwrittenUnitsConfig={setOverwrittenUnitsConfig}
          />

          <div>
            <label className='pr-2 mb-0 pt-2'>Date-Time:</label>
            <div>
              <label className='px-2 mb-0 pt-2'>Can edit date-time:</label>
              <input
                type='checkbox'
                disabled={canEditJSON}
                checked={isCustomEpoch}
                onChange={(e: any) => setIsCustomEpoch(e.target.checked)}
              />
            </div>

            <DateTimePicker
              defaultMode={CalendarTypes.ExactDate}
              dateFrom={convertDataEpochToDate(epoch)}
              dateTo={convertDataEpochToDate(epoch)}
              onDateRangeSelect={(from, to) => {
                setEpoch(
                  moment
                    .tz(moment(from).format('YYYY-MM-DD HH:mm:ss'), timezone)
                    .unix(),
                )
              }}
              timeEnabled
              disabled={!isCustomEpoch}
              showSecondInput={false}
              className='ml-2 mt-2'
              availableModes={[CalendarTypes.ExactDate]}
            />
          </div>

          <div className='pt-2'>
            <label className='pr-2 mb-0 pt-2'>Ignition:</label>
            <input
              type='checkbox'
              disabled={canEditJSON}
              checked={ignition}
              onChange={(e: any) => setIgnition(e.target.checked)}
            />
          </div>

          <GpsInput
            className='pt-2'
            initGps={fetchedGps}
            fetchGps={fetchGps}
            setGps={setGps}
            disabled={canEditJSON}
            unitsOfMeasurementConfig={overwrittenUnitsConfig}
          />

          <div className='pt-2'>
            <div>
              <label className='pr-2 mb-0 pt-2'>Sensors:</label>
              <AddSensorDataModal
                assetSensors={assetSensors}
                onAddWarning={onAddWarning}
                unitsOfMeasurementConfig={overwrittenUnitsConfig}
                disabled={canEditJSON}
              />
            </div>

            <SensorDataTable
              warnings={warnings}
              setWarnings={setWarnings}
              assetSensors={assetSensors}
              unitsOfMeasurementConfig={overwrittenUnitsConfig}
            />
          </div>
        </div>

        <hr />

        <div>
          <label className='pr-2 mb-0'>Preview JSON:</label>
          <input
            type='checkbox'
            checked={previewJSON}
            onChange={(e: any) => {
              setPreviewJSON(e.target.checked)
              setCanEditJSON(false)
            }}
          />
        </div>

        <Collapse isOpen={previewJSON} className='pt-2'>
          <div className='pb-2'>
            <label className='pr-2 mb-0'>Edit JSON:</label>
            <input
              type='checkbox'
              checked={canEditJSON}
              onChange={(e: any) => setCanEditJSON(e.target.checked)}
            />
          </div>

          <ReactJSONEditor
            content={jsonEditorContent}
            readOnly={!canEditJSON}
            onChange={onJsonEditorChange}
          />
        </Collapse>

        <hr />

        <div className='d-flex justify-content-end mt-3'>
          <Button
            color='success'
            onClick={() => setIsSubmitModalOpen(true)}
            disabled={isLoading || (canEditJSON && jsonEditorError)}
          >
            Submit
          </Button>
        </div>
      </RivataModule>

      <Modal
        isOpen={isSubmitModalOpen}
        toggle={() => setIsSubmitModalOpen((isOpen) => !isOpen)}
      >
        <ModalHeader toggle={() => setIsSubmitModalOpen((isOpen) => !isOpen)}>
          Modal title
        </ModalHeader>

        <ModalBody>Submit data to backend?</ModalBody>

        <ModalFooter>
          <Button color='success' onClick={onSubmit}>
            Submit
          </Button>

          <Button onClick={() => setIsSubmitModalOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <InfoModal
        open={!!info}
        header='Info'
        message={info}
        onConfirm={() => setInfo('')}
      />
    </>
  )
}
const TestHardwareDataGenerationWrapper: React.FC<Props> = ({
  locale,
  vin,
}) => {
  const { assetSensors } = useTypedSelector((state) => ({
    assetSensors: state.assetAdminInfo.sensorInfo.data as IAssetSensors,
  }))
  return assetSensors ? (
    <TestHardwareDataGeneration locale={locale} vin={vin} />
  ) : (
    <>
      <RivataModule
        title='Test Hardware Data Generation'
        locale={locale}
        marginTop={0}
        filters={undefined}
      >
        <StatusAlert
          customText='Asset does not have any sensors assigned'
          color='success'
          statusCode={undefined}
          statusText={undefined}
        />
      </RivataModule>
    </>
  )
}
export default TestHardwareDataGenerationWrapper
