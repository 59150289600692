import React, { useEffect, useRef } from 'react'
import config from '../../../config/appConfig'
import { IGps } from '../types'
import { getCircleIcon } from '../../../components/RivataMap/utils'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { UnitsOfMeasurementConfig } from '../../../constants/constants'
import { addMarkerDragEventListener } from './utils'

type Props = {
  gps: IGps
  setGps: React.Dispatch<React.SetStateAction<IGps>>
}

const GpsMap: React.FC<Props> = ({ gps, setGps }) => {
  const { unitsOfMeasurementConfig } = useTypedSelector((state) => ({
    unitsOfMeasurementConfig: state.auth.preferences.unitsOfMeasurementConfig,
  }))

  const mapRef = useRef<HTMLDivElement>(null)
  const map = useRef<H.Map | null>(null)
  const markerObject = useRef<H.map.Object | null>(null)
  const draggingOffset = useRef(new H.math.Point(0, 0))
  const isDragging = useRef(false)

  useEffect(() => {
    if (map.current) return

    const platform = new H.service.Platform({ apikey: config.mapApiKey })

    //@ts-expect-error //bug of @types/heremaps
    const engineType = H.Map.EngineType['HARP'];

    //@ts-expect-error //bug of @types/heremaps
    const defaultLayers = platform.createDefaultLayers({engineType, lg: 'en'})

    const newMap = new H.Map(
      mapRef.current as HTMLDivElement,
      defaultLayers.vector.normal.map,
      {
        engineType,
        pixelRatio: window.devicePixelRatio,
        center: {
          lat: gps.latitude,
          lng: gps.longitude,
        },
        zoom: 14,
      },
    )

    window.addEventListener('resize', () => {
      if (newMap) {
        newMap.getViewPort().resize()
      }
    })

    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(newMap))

    addMarkerDragEventListener(
      newMap,
      behavior,
      setGps,
      draggingOffset,
      isDragging,
    )

    const ui = H.ui.UI.createDefault(newMap, defaultLayers)

    ui.setUnitSystem(
      unitsOfMeasurementConfig.distance === UnitsOfMeasurementConfig.distance.km
        ? H.ui.UnitSystem.METRIC
        : H.ui.UnitSystem.IMPERIAL,
    )
    ui.getControl('mapsettings').setVisibility(false)

    map.current = newMap
  }, [gps, setGps, unitsOfMeasurementConfig])

  useEffect(() => {
    if (!map.current || isDragging.current) {
      return
    }

    map.current.setCenter({
      lat: gps.latitude,
      lng: gps.longitude,
    })

    map.current.setZoom(14)

    if (markerObject.current) map.current.removeObject(markerObject.current)

    const marker = new H.map.Marker(
      { lat: gps.latitude, lng: gps.longitude },
      {
        icon: getCircleIcon(gps.direction, 'default'),
        //@ts-expect-error //bug of @types/heremaps
        volatility: true,
      },
    )

    marker.draggable = true

    markerObject.current = map.current.addObject(marker)
  }, [gps, isDragging])

  return <div style={{ width: '100%', height: '500px' }} ref={mapRef} />
}

export default GpsMap
