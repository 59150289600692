import React, { useState, useEffect } from "react"
import { Row, Input, CustomInput } from "reactstrap"
import "../style.scss"

import { CronModes } from "../../../../../enums"
import TimeInput, { IAllowedRange } from "../../../../../components/TimeInput"
import moment from "moment"

interface Props {
    settings: ICronSettings,
    updateDateTime: (hours: number, minutes: number, day: number) => void,
    changeMode: (mode: number) => void,
    allowedTimeRange: IAllowedRange,
    currentMode: number
}

const Hourly: React.FC<Props> = ({
    settings,
    updateDateTime,
    changeMode,
    allowedTimeRange,
    currentMode
}) => {
    const [time, setTime] = useState("");

    useEffect(() => {
        if (time) {
            updateDateTime(Number(time.slice(0, 2)), Number(time.slice(3, 5)), settings.datetime.day);
        }
    }, [time, settings.datetime.day, updateDateTime])

    return (
        <>
            <Row className="flex-nowrap align-items-center justify-content-center p-3 mt-3 option-section">
                <CustomInput
                    type="radio"
                    id="everyNHours"
                    label="Every"
                    checked={settings.tabs.hourly.everyNHours}
                    onChange={() => changeMode(CronModes.everyNHours)}
                />
                <Input 
                    type="number"
                    disabled={!settings.tabs.hourly.everyNHours}
                    value={settings.tabs.hourly.everyNHours ? settings.datetime.hours : ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        updateDateTime(Number(e.target.value), settings.datetime.minutes, settings.datetime.day);
                    }}
                    className="ml-2 mr-2"
                    style={{ maxWidth: "75px" }}
                />
                <span>hour(s)</span>
            </Row>
            <Row className="flex-nowrap align-items-center justify-content-center p-3 option-section">
                <CustomInput
                    type="radio"
                    id="everyNMinutes"
                    label="Every"
                    checked={settings.tabs.hourly.everyNMinutes}
                    onChange={() => changeMode(CronModes.everyNMinutes)}
                />
                <Input 
                    type="number"
                    step={10}
                    disabled={!settings.tabs.hourly.everyNMinutes}
                    value={settings.tabs.hourly.everyNMinutes ? settings.datetime.minutes : ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        updateDateTime(settings.datetime.hours, Number(e.target.value), settings.datetime.day);
                    }}
                    className="ml-2 mr-2"
                    style={{ maxWidth: "75px" }}
                />
                <span>minute(s)</span>
            </Row>
            <Row className="flex-nowrap align-items-center justify-content-center p-3 option-section">
                <CustomInput
                    type="radio"
                    id="atHoursAndMinutes"
                    label="At"
                    checked={settings.tabs.hourly.atHoursAndMinutes}
                    onChange={() => changeMode(CronModes.atHoursAndMinutes)}
                    className="mr-2"
                />
                <TimeInput
                    value={currentMode !== CronModes.atHoursAndMinutes ? "" : moment(`${settings.datetime.hours}:${settings.datetime.minutes}`, "HH:mm").format("HH:mm")}
                    setValue={setTime}
                    dropdownItemsStep={10}
                    restrictToStep
                    allowedRange={allowedTimeRange}
                    disabled={currentMode !== CronModes.atHoursAndMinutes}
                />
            </Row>
        </>
    )
}

export default React.memo(Hourly)
