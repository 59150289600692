import React, { useEffect, useRef, useState } from 'react'
import { Input, Button, UncontrolledTooltip } from 'reactstrap'
import { IGps } from '../types'
import GpsMap from './GpsMap'
import { UnitsOfMeasurementConfig } from '../../../constants/constants'
import { ftToMeters, kmhToMph, metersToFt, mphToKmh } from '../../../utils'

const defUnits = {
  distance: 'km',
  speed: 'kmh',
}

type Props = {
  className?: string
  initGps: IGps
  fetchGps: () => void
  setGps: React.Dispatch<React.SetStateAction<IGps>>
  disabled?: boolean
  unitsOfMeasurementConfig: UnitsOfMeasurement
}

const GpsInput: React.FC<Props> = ({
  className,
  initGps,
  fetchGps,
  setGps,
  disabled,
  unitsOfMeasurementConfig,
}) => {
  const prevUnits = useRef({ ...unitsOfMeasurementConfig, ...defUnits })
  const [inputGps, setInputGps] = useState(initGps)

  useEffect(() => {
    if (
      prevUnits.current.distance === unitsOfMeasurementConfig.distance &&
      prevUnits.current.speed === unitsOfMeasurementConfig.speed
    )
      return

    const newGps = { ...inputGps }

    if (prevUnits.current.distance !== unitsOfMeasurementConfig.distance) {
      newGps.altitude =
        unitsOfMeasurementConfig.distance ===
        UnitsOfMeasurementConfig.distance.miles
          ? +metersToFt(newGps.altitude).toFixed(1)
          : +ftToMeters(newGps.altitude).toFixed(1)
    }

    if (prevUnits.current.speed !== unitsOfMeasurementConfig.speed) {
      newGps.speed =
        unitsOfMeasurementConfig.speed === UnitsOfMeasurementConfig.speed.mph
          ? +kmhToMph(newGps.speed).toFixed(1)
          : +mphToKmh(newGps.speed).toFixed(1)
    }

    prevUnits.current = unitsOfMeasurementConfig

    setInputGps(newGps)
  }, [inputGps, unitsOfMeasurementConfig])

  useEffect(() => {
    prevUnits.current = unitsOfMeasurementConfig
    setInputGps({
      ...initGps,
      altitude:
        unitsOfMeasurementConfig.distance ===
        UnitsOfMeasurementConfig.distance.miles
          ? +metersToFt(initGps.altitude).toFixed(1)
          : initGps.altitude,
      speed:
        unitsOfMeasurementConfig.speed === UnitsOfMeasurementConfig.speed.mph
          ? +kmhToMph(initGps.speed).toFixed(1)
          : initGps.speed,
    })
  }, [initGps, unitsOfMeasurementConfig])

  useEffect(() => {
    const metricGps: IGps = {
      ...inputGps,
      altitude:
        unitsOfMeasurementConfig.distance ===
        UnitsOfMeasurementConfig.distance.miles
          ? +ftToMeters(inputGps.altitude).toFixed(1)
          : inputGps.altitude,
      speed:
        unitsOfMeasurementConfig.speed === UnitsOfMeasurementConfig.speed.mph
          ? +mphToKmh(inputGps.speed).toFixed(1)
          : inputGps.speed,
    }

    setGps(metricGps)
  }, [setGps, inputGps, unitsOfMeasurementConfig])

  return (
    <div className={className}>
      <label className='pr-2 mb-0 pt-2'>GPS:</label>
      <div className='d-flex'>
        <div className='pl-2'>
          <Button className='mt-2' onClick={fetchGps} disabled={disabled}>
            Fetch Latest GPS
          </Button>
          <div>
            <label className='pr-2 mb-0 pt-2' style={{ minWidth: '90px' }}>
              latitude:
            </label>
            <Input
              type='number'
              min='-90'
              max='90'
              step='any'
              style={{ width: '120px', display: 'inline' }}
              value={inputGps.latitude}
              onChange={(e: any) => {
                if (+e.target.value >= -90 && +e.target.value <= -90)
                  setInputGps({ ...inputGps, latitude: +e.target.value })
              }}
              disabled={disabled}
            />
          </div>

          <div>
            <label className='pr-2 mb-0 pt-2' style={{ minWidth: '90px' }}>
              longitude:
            </label>
            <Input
              type='number'
              min='-180'
              max='180'
              step='any'
              style={{ width: '120px', display: 'inline' }}
              value={inputGps.longitude}
              onChange={(e: any) => {
                if (+e.target.value >= -180 && +e.target.value <= -180)
                  setInputGps({ ...inputGps, longitude: +e.target.value })
              }}
              disabled={disabled}
            />
          </div>

          <div>
            <label className='pr-2 mb-0 pt-2' style={{ minWidth: '90px' }}>
              altitude (
              {unitsOfMeasurementConfig.distance ===
              UnitsOfMeasurementConfig.distance.miles
                ? 'ft'
                : 'm'}
              ):
            </label>
            <Input
              type='number'
              min='0'
              step='any'
              style={{ width: '120px', display: 'inline' }}
              value={inputGps.altitude}
              onChange={(e: any) => {
                if (+e.target.value >= 0)
                  setInputGps({ ...inputGps, altitude: +e.target.value })
              }}
              disabled={disabled}
            />
          </div>

          <div>
            <label className='pr-2 mb-0 pt-2' style={{ minWidth: '90px' }}>
              speed (
              {unitsOfMeasurementConfig.speed ===
              UnitsOfMeasurementConfig.speed.mph
                ? 'mph'
                : 'kmh'}
              ):
            </label>
            <Input
              type='number'
              min='0'
              step='any'
              style={{ width: '120px', display: 'inline' }}
              value={inputGps.speed}
              onChange={(e: any) => {
                if (+e.target.value >= 0)
                  setInputGps({ ...inputGps, speed: +e.target.value })
              }}
              disabled={disabled}
            />
          </div>

          <div>
            <label className='pr-2 mb-0 pt-2' style={{ minWidth: '90px' }}>
              direction:
              <sup>
                <i id='directionInfo' className='ml-1 fa fa-info-circle' />
              </sup>
              <UncontrolledTooltip placement='auto' target={'directionInfo'}>
                Direction in degrees clockwise from North (0 - 359)
              </UncontrolledTooltip>
            </label>

            <Input
              type='number'
              min='0'
              max='359'
              style={{ width: '120px', display: 'inline' }}
              value={inputGps.direction}
              onChange={(e: any) => {
                if (+e.target.value >= 0 && +e.target.value < 360)
                  setInputGps({ ...inputGps, direction: +e.target.value })
              }}
              disabled={disabled}
            />
          </div>
        </div>
        <div className='pl-3 w-100'>
          <div className='p-1'>Drag and drop to change location</div>
          <GpsMap gps={inputGps} setGps={setInputGps} />
        </div>
      </div>
    </div>
  )
}

export default GpsInput
