import { IGps } from '../types'

export const addMarkerDragEventListener = (
  map: H.Map,
  behavior: H.mapevents.Behavior,
  setGps: React.Dispatch<React.SetStateAction<IGps>>,
  draggingOffset: React.MutableRefObject<H.math.Point>,
  isDragging: React.MutableRefObject<boolean>,
) => {
  map.addEventListener(
    'dragstart',
    (ev) => {
      var target = ev.target,
        //@ts-expect-error
        pointer = ev.currentPointer
      if (target instanceof H.map.Marker) {
        var targetPosition = map.geoToScreen(
          target.getGeometry() as H.geo.IPoint,
        )
        draggingOffset.current = new H.math.Point(
          pointer.viewportX - targetPosition.x,
          pointer.viewportY - targetPosition.y,
        )
        behavior.disable()
        isDragging.current = true
      }
    },
    false,
  )

  map.addEventListener(
    'dragend',
    (ev) => {
      var target = ev.target
      if (target instanceof H.map.Marker) {
        behavior.enable()

        setTimeout(() => (isDragging.current = false), 50)
      }
    },
    false,
  )

  map.addEventListener(
    'drag',
    (ev) => {
      var target = ev.target,
        //@ts-expect-error
        pointer = ev.currentPointer
      if (target instanceof H.map.Marker) {
        const geo = map.screenToGeo(
          pointer.viewportX - draggingOffset.current.x,
          pointer.viewportY - draggingOffset.current.y,
        )
        target.setGeometry(geo)

        setGps((gps) => ({
          ...gps,
          latitude: geo.lat,
          longitude: geo.lng,
        }))
      }
    },
    false,
  )
}
