import React from 'react'
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { UnitsOfMeasurementConfig } from '../../constants/constants'

type Props = {
  overwrittenUnitsConfig: UnitsOfMeasurement
  setOverwrittenUnitsConfig: (units: UnitsOfMeasurement) => void
}

const UnitsOfMeasurementSelector: React.FC<Props> = ({
  overwrittenUnitsConfig,
  setOverwrittenUnitsConfig,
}) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle caret>Units of Measurement</DropdownToggle>
      <DropdownMenu>
        <div className='d-flex align-items-center pb-2 px-3'>
          <div style={{ minWidth: '90px' }}>altitude:</div>

          <input
            type='radio'
            id='miles'
            checked={
              overwrittenUnitsConfig.distance ===
              UnitsOfMeasurementConfig.distance.miles
            }
            onChange={() =>
              setOverwrittenUnitsConfig({
                ...overwrittenUnitsConfig,
                distance: UnitsOfMeasurementConfig.distance.miles,
              })
            }
          />
          <label className='ml-1 mb-0' htmlFor='miles'>
            ft
          </label>

          <input
            className='ml-3'
            type='radio'
            id='km'
            checked={
              overwrittenUnitsConfig.distance ===
              UnitsOfMeasurementConfig.distance.km
            }
            onChange={() =>
              setOverwrittenUnitsConfig({
                ...overwrittenUnitsConfig,
                distance: UnitsOfMeasurementConfig.distance.km,
              })
            }
          />
          <label className='ml-1 mb-0' htmlFor='km'>
            m
          </label>
        </div>

        <div className='d-flex align-items-center pb-2 px-3'>
          <div style={{ minWidth: '90px' }}>speed:</div>

          <input
            type='radio'
            id='mph'
            checked={
              overwrittenUnitsConfig.speed ===
              UnitsOfMeasurementConfig.speed.mph
            }
            onChange={() =>
              setOverwrittenUnitsConfig({
                ...overwrittenUnitsConfig,
                speed: UnitsOfMeasurementConfig.speed.mph,
              })
            }
          />
          <label className='ml-1 mb-0' htmlFor='mph'>
            mph
          </label>

          <input
            className='ml-3'
            type='radio'
            id='kmh'
            checked={
              overwrittenUnitsConfig.speed ===
              UnitsOfMeasurementConfig.speed.kmh
            }
            onChange={() =>
              setOverwrittenUnitsConfig({
                ...overwrittenUnitsConfig,
                speed: UnitsOfMeasurementConfig.speed.kmh,
              })
            }
          />
          <label className='ml-1 mb-0' htmlFor='kmh'>
            kmh
          </label>
        </div>

        <div className='d-flex align-items-center pb-2 px-3'>
          <div style={{ minWidth: '90px' }}>pressure:</div>

          <input
            type='radio'
            id='psi'
            checked={
              overwrittenUnitsConfig.pressure ===
              UnitsOfMeasurementConfig.pressure.psi
            }
            onChange={() =>
              setOverwrittenUnitsConfig({
                ...overwrittenUnitsConfig,
                pressure: UnitsOfMeasurementConfig.pressure.psi,
              })
            }
          />
          <label className='ml-1 mb-0' htmlFor='psi'>
            psi
          </label>

          <input
            className='ml-3'
            type='radio'
            id='bar'
            checked={
              overwrittenUnitsConfig.pressure ===
              UnitsOfMeasurementConfig.pressure.bar
            }
            onChange={() =>
              setOverwrittenUnitsConfig({
                ...overwrittenUnitsConfig,
                pressure: UnitsOfMeasurementConfig.pressure.bar,
              })
            }
          />
          <label className='ml-1 mb-0' htmlFor='bar'>
            bar
          </label>
        </div>

        <div className='d-flex align-items-center px-3'>
          <div style={{ minWidth: '90px' }}>temperature:</div>

          <input
            type='radio'
            id='fahrenheit'
            checked={
              overwrittenUnitsConfig.temperature ===
              UnitsOfMeasurementConfig.temperature.fahrenheit
            }
            onChange={() =>
              setOverwrittenUnitsConfig({
                ...overwrittenUnitsConfig,
                temperature: UnitsOfMeasurementConfig.temperature.fahrenheit,
              })
            }
          />
          <label className='ml-1 mb-0' htmlFor='fahrenheit'>
            fahrenheit
          </label>

          <input
            className='ml-3'
            type='radio'
            id='celsius'
            checked={
              overwrittenUnitsConfig.temperature ===
              UnitsOfMeasurementConfig.temperature.celsius
            }
            onChange={() =>
              setOverwrittenUnitsConfig({
                ...overwrittenUnitsConfig,
                temperature: UnitsOfMeasurementConfig.temperature.celsius,
              })
            }
          />
          <label className='ml-1 mb-0' htmlFor='celsius'>
            celsius
          </label>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UnitsOfMeasurementSelector
