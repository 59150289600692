import { eventDataSensor, IAssetSensors, IEventDataWarnings } from './types'

export const warningsToSensors = (
  warnings: IEventDataWarnings,
  assetSensors: IAssetSensors,
  epoch: number,
) => {
  const res: Array<eventDataSensor> = []

  if (warnings.smarthubTemp)
    Object.entries(warnings.smarthubTemp).forEach(([sensor_mac, value]) => {
      res.push({
        sensor_type: 'smarthub',
        packet_type: 'temperature',
        sensor_mac,
        epoch,
        value,
      })
    })

  if (warnings.smarthubVib)
    Object.entries(warnings.smarthubVib).forEach(([mac, vib]) => {
      res.push({
        sensor_type: 'smarthub',
        packet_type: 'fake_vibration',
        mac,
        epoch,
        value: vib.value,
        bearing_outlier: vib.bearing ? 1 : 0,
        tire_outlier: vib.tire ? 1 : 0,
      })
    })

  if (warnings.tpms)
    Object.entries(warnings.tpms).forEach(([mac, value]) => {
      res.push({
        sensor_type: 'tire_pressure',
        short_mac: mac.replaceAll('-', '').slice(-4),
        value,
      })
    })

  if (warnings.linePressure !== undefined)
    res.push({
      sensor_type: 'tire_pressure',
      short_mac: assetSensors.line_pressure.mac.replaceAll('-', '').slice(-4),
      value: warnings.linePressure,
    })

  if (warnings.axleLoad !== undefined)
    res.push({
      sensor_type: 'tire_pressure',
      short_mac: assetSensors.axle_load.mac.replaceAll('-', '').slice(-4),
      value: warnings.axleLoad,
    })

  return res
}
