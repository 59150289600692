import {
  getMarkerPinMap,
  getCircleIcon,
  LayerTypes,
  behaviorUi,
  healthColors,
  pinMap,
} from './utils'
import config from '../../config/appConfig'
import { kmhToMph, metersToFt } from '../../utils'
import { getTooltipCoordinates } from '../RivataMapCluster/utils'
import { getGpsDirectinFromDeg } from '../../utils/utils'
import { convertDataEpochToDate } from '../../utils'
import { UnitsOfMeasurementConfig } from '../../constants/constants'
import { orderBy } from 'lodash'
import { getClearLabel } from '../../utils'

export const H = window.H || {
  map: {
    Icon: () => {},
  },
}

export const handleLatestLocationZoom = (
  hMap,
  bbox,
  initialBoundsZoom = {},
) => {
  const { zoom, bounds } = initialBoundsZoom
  if (!hMap) return
  if (zoom && bounds) {
    hMap.getViewModel().setLookAtData({
      zoom,
      bounds: bounds,
    })
  } else if (bbox) {
    hMap.getViewModel().setLookAtData({
      bounds: bbox,
    })
  }
}

export const startMarkersClustering = (
  locations,
  hMap,
  isWarningDetails,
  addInfoBubble,
  playbackMode = false,
  latestPointHasWarning = false,
  bubble,
) => {
  const markersMap = getMarkerPinMap()
  const zIndexValues = {
    default: 10,
    warn: 20,
    critWarn: 30,
    pin: 40,
  }

  // already sorted by epoch
  const currentVehicleLocation = locations[locations.length - 1]

  const dataPoints = locations.map((item) => {
    return new H.clustering.DataPoint(item.latitude, item.longitude, null, item)
  })

  const startLocation = locations[0]

  const startMarker = new H.map.Marker(
    {
      lat: startLocation.latitude,
      lng: startLocation.longitude,
    },
    { icon: pinMap.ok },
  )

  startMarker.setData({
    latestPoint: startLocation,
  })

  startMarker.setZIndex(zIndexValues.pin)
  startMarker.addEventListener('pointerenter', addInfoBubble)

  hMap.addObject(startMarker)

  if (playbackMode) {
    locations.forEach((loc, idx) => {
      const latestWarningPoint = { data: null, epoch: 0 }
      let pinkey = 'default'
      let zIndex = zIndexValues.default
      let showPin = false

      if (isWarningDetails) {
        const markerData = loc

        if (markerData.warning_info.has_warning) {
          if (markerData.epoch > latestWarningPoint.epoch) {
            latestWarningPoint.data = markerData
            latestWarningPoint.epoch = markerData
          }

          if (pinkey !== 'critWarn') {
            if (markerData.has_critical_warning) {
              pinkey = 'critWarn'
            } else {
              pinkey = 'warn'
            }
          }
        }
      }

      // pin = latest location
      if (idx === locations.length - 1) {
        showPin = true
      }

      if (showPin) zIndex = zIndexValues.pin
      else if (pinkey === 'critWarn') zIndex = zIndexValues.critWarn
      else if (pinkey === 'warn') zIndex = zIndexValues.warn

      const marker = new H.map.Marker(
        {
          lat: loc.latitude,
          lng: loc.longitude,
        },
        {
          icon: showPin
            ? pinMap.critWarn
            : getCircleIcon(
                latestWarningPoint.data
                  ? latestWarningPoint.data.heading
                  : loc.heading,
                pinkey,
                true,
                idx + 1,
              ),
        },
      )

      marker.setData({
        latestPoint: loc,
      })

      marker.setZIndex(zIndex)
      marker.addEventListener('pointerenter', addInfoBubble)

      if (latestPointHasWarning) {
        addInfoBubble(marker)
      } else {
        bubble.close()
      }

      hMap.addObject(marker)
    })
  } else {
    const clusteredDataProvider = new H.clustering.Provider(dataPoints, {
      theme: {
        // aggregate gps points to clusters (on load and only once) and clusters are displayed based on current zoom level
        getClusterPresentation: (cluster) => {
          const latestPoint = Object.values(cluster).reduce((accum, curr) => {
            // at 0 index always latest - data is sorted by epoch
            if (Array.isArray(curr)) return curr[0].data

            return accum
          }, {})

          const latestWarningPoint = { data: null, epoch: 0 }
          let pinkey = 'default'
          let zIndex = zIndexValues.default
          let showPin = false

          if (isWarningDetails) {
            cluster.forEachDataPoint((marker) => {
              const markerData = marker.getData()

              // if inside cluster are warnings gps points then search for latest point with warning and display whole cluster as warning point
              if (markerData.showAsWarning) {
                if (markerData.epoch > latestWarningPoint.epoch) {
                  latestWarningPoint.data = markerData
                  latestWarningPoint.epoch = markerData
                }

                if (pinkey !== 'critWarn') {
                  if (markerData.has_critical_warning) {
                    pinkey = 'critWarn'
                  } else {
                    pinkey = 'warn'
                  }
                }
              }
            })
          }

          // pin = latest location
          if (latestPoint.id === currentVehicleLocation.id) {
            showPin = true
          }

          if (showPin) zIndex = zIndexValues.pin
          else if (pinkey === 'critWarn') zIndex = zIndexValues.critWarn
          else if (pinkey === 'warn') zIndex = zIndexValues.warn

          // Create a marker for the cluster
          const clusterMarker = new H.map.Marker(cluster.getPosition(), {
            icon: showPin
              ? pinMap.critWarn
              : getCircleIcon(
                  latestWarningPoint.data
                    ? latestWarningPoint.data.heading
                    : latestPoint.heading,
                  pinkey,
                ),
            // Set min/max zoom with values from the cluster, otherwise
            // clusters will be shown at all zoom levels
            min: cluster.getMinZoom(),
            max: cluster.getMaxZoom(),
          })

          // Bind cluster data to the marker
          clusterMarker.setData({
            latestPoint: latestWarningPoint.data
              ? latestWarningPoint.data
              : latestPoint,
          })
          clusterMarker.setZIndex(zIndex)
          clusterMarker.addEventListener('pointerenter', addInfoBubble)

          return clusterMarker
        }, // https://developer.here.com/documentation/maps/3.1.30.7/dev_guide/topics/clustering.html
        getNoisePresentation: function (noisePoint) {
          const data = noisePoint.getData()

          let showPin = false
          const pinkey = data.has_critical_warning
            ? 'critWarn'
            : data.showAsWarning
            ? 'warn'
            : 'default'

          if (data.id === currentVehicleLocation.id) showPin = true

          // Create a marker for noise points:
          const noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
            icon: showPin
              ? markersMap[pinkey]
              : getCircleIcon(data.heading, pinkey),

            // Use min zoom from a noise point to show it correctly at certain zoom levels
            min: noisePoint.getMinZoom(),
          })

          // Bind noise point data to the marker:
          noiseMarker.setData({ latestPoint: data })

          noiseMarker.addEventListener('pointerenter', addInfoBubble)

          return noiseMarker
        },
      },
      clusteringOptions: {
        // strategy of cluster displaying: https://stackoverflow.com/questions/48265696/here-maps-clustering-is-not-accurate-unexpected-behaviour
        // without that clusters can be too close to each other
        strategy: H.clustering.Provider.Strategy.DYNAMICGRID,
        // Maximum radius of the neighbourhood
        eps: 11,
        // minimum weight of points required to form a cluster
        minWeight: playbackMode ? 99999 : 2,
      },
    })

    // Create a layer that will consume objects from our clustering provider
    const clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider)

    hMap.addLayer(clusteringLayer)

    handleLatestLocationZoom(hMap, null)

    return clusteringLayer
  }
}

const resize = (hMap) => {
  if (hMap) {
    hMap.getViewPort().resize()
  }
}

export const updateBaseLayer = (layerType, hMap, defaultLayers) => {
  if (!layerType || !hMap || !defaultLayers) return

  const layerChoices = {
    [LayerTypes.NORMAL]: defaultLayers.vector.normal.map,
    [LayerTypes.SATELLITE]: defaultLayers.raster.satellite.map,
  }

  if (layerType in layerChoices) {
    hMap.setBaseLayer(layerChoices[layerType])
  }
}

export const initMap = (mapRef, unitsOfMeasurementConfig) => {
  const platform = new H.service.Platform({
    apikey: config.mapApiKey,
  })
  const engineType = H.Map.EngineType['HARP']
  const defaultLayers = platform.createDefaultLayers({ engineType, lg: 'en' })

  const hMap = new H.Map(mapRef, defaultLayers.vector.normal.map, {
    engineType,
    center: { lat: 41.850033, lng: -87.6500523 }, // center of U.S. default
    pixelRatio: window.devicePixelRatio || 1,
  })

  window.addEventListener('resize', () => resize(hMap))
  const { ui } = behaviorUi(hMap, defaultLayers, unitsOfMeasurementConfig)

  const bubble = new H.ui.InfoBubble(
    { lng: 13.4, lat: 52.51 },
    {
      content: '',
    },
  )
  bubble.addClass('info-bubble')
  ui.addBubble(bubble)

  ui.getControl('zoom').setVisibility(true)
  ui.getControl('mapsettings').setVisibility(false)

  return { hMap, defaultLayers, ui, bubble }
}

const removeInfoBubble = (bubble) => {
  if (!bubble) return
  bubble.close()
}

export const composeBubble = (
  e,
  bubble,
  unitsOfMeasurementConfig,
  hMap,
  playbackMode = false,
) => {
  const data = e.target
    ? e.target.getData().latestPoint
    : e.getData().latestPoint
  if (!data) return

  data.elevation = data.elevation < 0 ? 0 : data.elevation

  const {
    name,
    epoch,
    vin,
    formatted_datetime,
    speed,
    elevation,
    showAsWarning,
    has_critical_warning,
    heading,
    latitude,
    longitude,
    warning_info,
  } = data

  const map = document.getElementById('rivata-map')

  const { top, left } = getTooltipCoordinates(map, data, e, 25, -145, hMap)

  const formattedSpeed = isNaN(speed)
    ? 'NA'
    : unitsOfMeasurementConfig.speed === UnitsOfMeasurementConfig.speed.mph
    ? kmhToMph(speed).toFixed(1) + 'mph'
    : speed.toFixed(1) + 'kmh'
  const formattedElevation =
    unitsOfMeasurementConfig.distance ===
    UnitsOfMeasurementConfig.distance.miles
      ? metersToFt(elevation).toFixed(1) + 'ft'
      : elevation + 'm'

  const earliestWarning = orderBy(warning_info.details, 'timestamp')[0]
  const latestRecoveredWarning = orderBy(
    warning_info.details,
    'recovered_timestamp',
  )[0]

  const notRecoveredState =
    latestRecoveredWarning?.recovered_timestamp === null ||
    latestRecoveredWarning?.recovered_timestamp > epoch

  let labelColor = '#6fbdf1'

  if (playbackMode) {
    labelColor =
      has_critical_warning && notRecoveredState
        ? healthColors[2].color
        : warning_info.has_warning && notRecoveredState
        ? healthColors[1].color
        : warning_info.has_warning && !notRecoveredState
        ? healthColors[0].color
        : '#6fbdf1'
  } else {
    labelColor = has_critical_warning
      ? healthColors[2].color
      : showAsWarning
      ? healthColors[1].color
      : '#6fbdf1'
  }

  const warningsDescription = warning_info.details
    .map((wi) => {
      return getClearLabel(wi.cause)
    })
    .join(', ')

  bubble.setContent(`
    <div id="details-buble" style="position: absolute; top: ${top}px; left: ${left}px; padding: 15px 10px 10px 15px; margin-top: 10px; margin-left: 10px">
      <div class="alert-primary show" role="alert" aria-live="assertive" aria-atomic="true" style="background-color: ${labelColor}; border-color: ${labelColor};">
        <div class="label-line"></div>
        <div class="bubble-body">
          <div class="row-wrapper">
            <div class="key">${name ? 'Name' : 'VIN'}</div>
            <div class="value">${name ? name : vin}</div>
          </div>
          <div class="row-wrapper">
            <div class="key">Date</div>
            <div class="value">${formatted_datetime}</div>
          </div>
          <div class="row-wrapper">
            <div class="key">Heading</div>
            <div class="value">${getGpsDirectinFromDeg(heading)}</div>
          </div>
          <div class="row-wrapper">
            <div class="key">Speed</div>
            <div class="value">${formattedSpeed}</div>
          </div>
          <div class="row-wrapper">
            <div class="key">Elevation</div>
            <div class="value">${formattedElevation}</div>
          </div>
          <div class="row-wrapper">
            <div class="key">Location</div>
            <div class="value">${latitude}, ${longitude}</div>
          </div>
          ${
            warning_info && warning_info.has_warning
              ? `
            <div class='row-wrapper'>
              <div class='key'>
                Warnings ${notRecoveredState ? 'Generated' : 'Recovered'}
              </div>
              <div class='value'>
                ${
                  notRecoveredState
                    ? convertDataEpochToDate(
                        earliestWarning.timestamp,
                        null,
                        null,
                        true,
                      )
                    : convertDataEpochToDate(
                        latestRecoveredWarning.recovered_timestamp,
                        null,
                        null,
                        true,
                      )
                }
              </div>
            </div>
            <div class='row-wrapper'>
              <div class='key'>Warnings</div>
              <div class='value'>
                ${warningsDescription}
              </div>
            </div>
            `
              : ''
          }
        </div>
      </div>
    </div>
  `)

  bubble.setPosition(e.target ? e.target.getGeometry() : e.getGeometry())

  document
    .querySelector('#details-buble')
    .addEventListener('pointerleave', () => removeInfoBubble(bubble))

  bubble.open()
}

export const addLocationButton = (hMap, ui, bbox) => {
  const elem = document.querySelector('.latest_location')

  if (elem && ui.getControl('latest_location')) {
    ui.removeControl('latest_location')
  }

  const control = new H.ui.Control()

  const btn = new H.ui.base.Button({
    label: `<?xml version="1.0" encoding="utf-8"?>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
    <style type="text/css">
      .st0{fill:#414042;}
      .st1{fill:none;stroke:#414042;stroke-width:2;stroke-miterlimit:10;}
    </style>
    <circle class="st0" cx="49.86" cy="47.71" r="11.88"/>
    <circle class="st1" cx="49.86" cy="47.71" r="15.83"/>
    <line class="st1" x1="49.86" y1="27.26" x2="49.86" y2="31.09"/>
    <line class="st1" x1="49.86" y1="64.33" x2="49.86" y2="68.16"/>
    <line class="st1" x1="29.41" y1="47.71" x2="33.24" y2="47.71"/>
    <line class="st1" x1="66.48" y1="47.71" x2="70.31" y2="47.71"/>
    </svg>`,
  })
  btn.addClass('latest_location')

  control.addChild(btn)
  control.setAlignment('right-bottom')

  if (!ui.getControl('latest_location')) {
    ui.addControl('latest_location', control)
  }

  const newElem = btn.getElement()

  if (newElem) {
    newElem.addEventListener('click', () => {
      if (!hMap || !bbox) return

      handleLatestLocationZoom(hMap, bbox)
    })
  }
}
